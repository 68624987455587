import { useMemo } from 'react';
import { Input as RawInput } from '@wearetla/tla-essentials-tools/partials/forms';
import '/assets/styles/partials/forms/input.scss';

const Input = ({ className: classNameProp, ...props }) => {
	const { className } = useMemo(() => {
		return {
			className: (
				(classNameProp ?? '') +
				(props.popLabel ? ' pop-label' : '') +
				(props.label ? ' has-label' : '') +
				((props.disabled || props.readOnly) ? ' disabled' : '') +
				(props.icon ? ' has-icon' : '') +
				(props.block ? ' block' : '') +
				(props.inline ? ' inline' : '') +
				(props.multiLine ? ' multi-line' : '') +
				(props.inlineBlock ? ' inline block' : '') +
				(props.hollow ? ' hollow' : '') +
				(props.big ? ' big' : '') +
				(props.small ? ' small' : '') +
				(props.z ? ' z-' + props.z : '')
			),
		}
	}, [classNameProp, props])
	return <RawInput {...props} className={className} />
}

export default Input;